<template>
    <div class="custom-pagination" v-if="totalPages > 1">
        <div
            class="custom-pagination-btn custom-pagination-left-btn"
            @click="updatePage(page - 1)"
        >
            &lt;&lt;
        </div>
        <div
            :class="['custom-pagination-item', { active: page === i }]"
            v-for="i in totalPages"
            @click="updatePage(i)"
            :key="i"
        >
            {{ i }}
        </div>
        <div
            class="custom-pagination-btn custom-pagination-right-btn"
            @click="updatePage(page + 1)"
        >
            &gt;&gt;
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "page",
        event: "updatePage",
    },
    props: {
        page: {
            type: Number,
            default: 1,
        },
        totalResults: {
            default: 0,
            type: Number,
        },
        perPageLimit: {
            default: 9,
            type: Number,
        },
    },
    methods: {
        updatePage(pageNumber) {
            if (pageNumber > 0 && pageNumber <= this.totalPages) {
                console.log(pageNumber, this.totalPages)
                this.$emit("updatePage", pageNumber)
            }
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalResults / this.perPageLimit)
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-pagination {
    padding: 10px;
    display: flex;
    justify-content: center;
    margin: 15px 0;

    .custom-pagination-btn {
        cursor: pointer;
        border-radius: 50%;
        padding: 0 3.5px;
        transition: 0.4s;
        &:hover {
            background: rgb(218, 218, 218);
        }
    }
    .custom-pagination-left-btn {
        margin-right: 5px;
    }
    .custom-pagination-right-btn {
        margin-left: 5px;
    }
    .custom-pagination-item {
        cursor: pointer;
        padding: 0 10px;
        margin: 0 2px;
        border-radius: 50%;
        transition: 0.4s;
        &:hover {
            background: rgb(218, 218, 218);
        }
        &.active {
            background: rgb(255, 201, 100);
        }
    }
}
</style>
