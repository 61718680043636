<template>
    <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="profile-edit-left">
            <h2><i class="fa fa-search"></i> SEARCH</h2>
            <form>
                <input
                    type="text"
                    name="searchkey"
                    v-model="searchkey"
                    placeholder="Enter your pincode.."
                    maxlength="6"
                    style="width: 100%;background-color: #fafafa;border:none;border-bottom: 1px solid #ea6a69;padding: 15px;"
                />

                <select
                    name="searchcat"
                    v-model="searchcat"
                    id="searchcat"
                    style="width: 100%;background-color: #fafafa;padding: 15px;"
                >
                    <option disabled value="">Choose Category..</option>
                    <option value="">All Category</option>
                    <option v-for="(user, index) in categores" :key="index" :value="user.id">{{
                        user.name
                    }}</option>
                </select>
                <select v-model="city" style="width: 100%;background-color: #fafafa;padding: 15px;">
                    <option disabled value="">Select City</option>
                    <option value="">All City</option>
                    <option
                        v-for="(user, index) in all_city"
                        :key="index"
                        :value="user.city_code"
                        >{{ user.city_name }}</option
                    >
                </select>

                <!-- <VueSimpleRangeSlider
                           style="width: 300px;"
                           :min="0"
                           :max="100"
                           v-model="number"
                        /> -->

                <!--<div class="range-wrap">-->
                <!--  <div class="range-value" id="rangeV"></div>-->
                <!--  <input-->
                <!--    id="range"-->
                <!--    type="range"-->
                <!--    min="0"-->
                <!--    max="100"-->
                <!--    value="0"-->
                <!--    step="1"-->
                <!--  />-->
                <!--</div>-->
                <button type="submit" @click="search">SEARCH NOW</button>
            </form>
        </div>
        <div class="profile-edit-left">
            <h2><i class="fa fa-shopping-bag"></i> LATEST PRODUCTS LISTED</h2>
        </div>
        <div class="profile-edit-left">
            <h2><i class="fa fa-bars"></i> CATEGORIES</h2>
            <ul class="left-profile-link" v-for="user in categores" :key="user.id">
                <li>
                    <div class="row">
                        <div class="col-sm-3">
                            <img
                                class="categorie_image"
                                v-bind:style="{ background: user.color }"
                                :src="imgsrc + user.img"
                                style="width: 45px;height: 45px;margin:0px;"
                            />
                        </div>
                        <div class="col-sm-9" style="background-color:#fafafa;padding: 10px;">
                            <a href="#" @click="filterCategory(user.id)"
                                >{{ user.name }}<span style="padding:0px;">3</span></a
                            >
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!--<div class="profile-edit-left">-->
        <!--  <h2><i class="fa fa-bars"></i> SUBCATEGORIES</h2>-->
        <!--  <ul-->
        <!--    class="left-profile-link"-->
        <!--    v-for="(user, index) in subbcategory"-->
        <!--    :key="index"-->
        <!--  >-->
        <!--    <li>-->
        <!--      <div class="row">-->
        <!--        <div class="col-sm-3">-->
        <!--          <img-->
        <!--            class="categorie_image"-->
        <!--            v-bind:style="{ background: user.color }"-->
        <!--            :src="imgsrc + user.img"-->
        <!--            style="width: 45px;height: 45px;margin:0px;"-->
        <!--          />-->
        <!--        </div>-->
        <!--        <div-->
        <!--          class="col-sm-9"-->
        <!--          style="background-color:#fafafa;padding: 10px;"-->
        <!--        >-->
        <!--          <a href="#"-->
        <!--            >{{ user.name }}<span style="padding:0px;">3</span></a-->
        <!--          >-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </li>-->

        <!--  </ul>-->
        <!--</div>-->
        <div class="profile-edit-left">
            <h2><i class="fa fa-share-alt"></i> FOLLOW US ON</h2>
            <ul class="left-profile-link">
                <li style="background-color: #ffffff;display: inline;">
                    <a href="#"><i class="fa fa-facebook-f" style="width: 46px;"></i></a>
                </li>
                <li style="background-color: #ffffff;display: inline;">
                    <a href="#"><i class="fa fa-google-plus" style="width: 46px;"></i></a>
                </li>
                <li style="background-color: #ffffff;display: inline;">
                    <a href="#"><i class="fa fa-pinterest-p" style="width: 46px;"></i></a>
                </li>
                <li style="background-color: #ffffff;display: inline;">
                    <a href="#"><i class="fa fa-linkedin" style="width: 46px;"></i></a>
                </li>
                <li style="background-color: #ffffff;display: inline;">
                    <a href="#"><i class="fa fa-instagram" style="width: 46px;"></i></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script src="https://unpkg.com/vuejs-paginate@latest"></script>
<script src="https://unpkg.com/vuejs-paginate@0.9.0"></script>

<script>
import axios from "axios"
//  import { HTTP } from "../_helper/http-constants";

//   import VueSimpleRangeSlider from 'vue-simple-range-slider';
//    import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css';

import "vue-select/src/scss/vue-select.scss"
import vSelect from "vue-select"
import request from "../apis/request"

export default {
    name: "surplus",
    delimiters: ["${", "}"], // Avoid Twig conflicts

    components: {
        // vueDropzone: vue2Dropzone,
        //  VueSimpleRangeSlider,
        vSelect,
    },

    data() {
        return {
            //  imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            //   imgsrc: "http://localhost/backend/public/admin_profile/",

            imgsrc: axios.defaults.uploadURL,

            filelist: [],

            ress: {},
            // number: 0,
            categores: null,
            subcategores: null,
            subbcategory: null,
            searchcat: "",
            searchkey: "",
            listproduct: "",
            state: "nostate",
            city: "",
            searchzip: "",
            all_city: null,

            action: "/surplus",
        }
    },

    methods: {
        search: function(e) {
            e.preventDefault()

            this.$router.push({
                path: "/search",
                query: {
                    id: this.searchcat,
                    name: this.searchkey,
                    zip: this.searchzip,
                    state: this.state,
                    city: this.city,
                },
            })
        },

        clickCallback(pageNum) {
            console.log(pageNum)
        },

        filterCategory(categoryid, page) {
            console.log(categoryid)
            if (typeof page === "undefined") {
                page = 1
            }

            request
                .get("/listproduct-search/" + categoryid + "?page=" + page)
                .then(
                    (response) => (
                        (this.listproductPage = response.data),
                        (this.listproduct = response.data.data),
                        (this.non_filtered_teachers = response.data)
                    )
                )
        },

        getsubcategory() {
            request
                .get("/sub-categories/" + this.category)
                .then((response) => (this.subcategores = response.data))
        },

        getResults(page) {
            if (typeof page === "undefined") {
                page = 1
            }

            request
                .get("/listproduct?page=" + page)
                .then(
                    (response) => (
                        (this.listproductPage = response.data),
                        (this.listproduct = response.data.data),
                        (this.non_filtered_teachers = response.data)
                    )
                )
        },
    },

    created() {
        request
            .get("/categories")

            .then((response) => (this.categores = response.data))

        request
            .get("/category")

            .then((response) => (this.subbcategory = response.data))

        this.getResults()

        request
            .get("/all_city")

            .then((response) => (this.all_city = response.data))

        // axios
        //   .get("/api/listproduct")

        //   .then((response) => (this.listproduct = response.data));
    },
}
</script>

<style>
select {
    font-family: "FontAwesome", "sans-serif";
}
input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: #ea6a69;
    border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ea6a69;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ea6a69;
}
.range-wrap {
    /*width: 500px;*/
    position: relative;
}
.range-value {
    position: absolute;
    top: -50%;
}
.range-value span {
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #ea6a69;
    color: #fff;
    font-size: 12px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
}
.range-value span:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #ea6a69;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
}
[v-cloak] {
    display: none;
}
.pagination {
    /* display: inline-block; */
    margin: 30px 100px;
}
.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    /* margin: 0 4px; */
}
/* .pagination a.active {
  background-color: #8c0526;
  color: white;
  border: 1px solid #4caf50;
} */
.pagination a:hover:not(.active) {
    background-color: #8c0526;
}
.page-item.active a {
    color: white;
    background-color: #8c0526;
}
</style>
