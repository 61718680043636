<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>SEARCH PRODUCTS</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12">
                        <div class="profile-edit" style="padding: 5px;border-radius: 30px;">
                            <h2 class="text-center p-0" style="border-bottom: none;">
                                Search Products
                            </h2>
                        </div>

                        <div class="my-4 small" v-if="surplusProducts.length === 0 && !isLoading">
                            No Products Found
                        </div>

                        <div class="row">
                            <SurplusProductItem
                                v-for="product in surplusProductsFromSearchedCategory"
                                :key="product.id"
                                :product="product"
                            />
                        </div>
                        <template v-if="includesOtherCategory">
                            <h4 class="mt-3">Results from other categories</h4>
                            <hr />
                        </template>
                        <div class="row">
                            <SurplusProductItem
                                v-for="product in surplusProductsFromOtherCategory"
                                :key="product.id"
                                :product="product"
                            />
                        </div>

                        <div style="text-align: center;">
                            <Pagination
                                v-model="page"
                                :perPageLimit="perPageLimit"
                                :totalResults="totalResults"
                            />
                        </div>
                    </div>
                    <!--<RightBar></RightBar>-->
                    <div class="col-lg-4 col-md-12 col-sm-12">
                        <DataFilter
                            v-model="surplusProducts"
                            :page="page"
                            :perPageLimit="perPageLimit"
                            @updatePage="page = $event"
                            @updateTotalResults="totalResults = $event"
                            @updateData="updateData"
                            @filterProcessing="filterProcessing"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://unpkg.com/vuejs-paginate@latest"></script>
<script src="https://unpkg.com/vuejs-paginate@0.9.0"></script>

<script>
import axios from "axios"
import RightBar from "../components/rightbar.vue"
import DataFilter from "./utils/Filter.vue"
import Pagination from "./Pagination.vue"
import SurplusProductItem from "./utils/SurplusProductItem.vue"
import scrollTop from "./utils/scrollTop"
import request from "../apis/request"

//  import { HTTP } from "../_helper/http-constants";

//   import VueSimpleRangeSlider from 'vue-simple-range-slider';
//    import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css';

export default {
    name: "surplus",
    delimiters: ["${", "}"], // Avoid Twig conflicts

    components: {
        // vueDropzone: vue2Dropzone,
        //  VueSimpleRangeSlider,
        RightBar,
        Pagination,
        DataFilter,
        SurplusProductItem,
    },

    data() {
        return {
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,
            totalResults: 0,
            page: 1,
            perPageLimit: 12,
            surplusProducts: [],
            isLoading: false,
            includesOtherCategory: false,
        }
    },
    async mounted() {
        scrollTop()
    },
    computed: {
        surplusProductsFromSearchedCategory() {
            return this.surplusProducts.filter((product) => !product.other_category)
        },
        surplusProductsFromOtherCategory() {
            return this.surplusProducts.filter((product) => product.other_category)
        },
    },
    methods: {
        filterProcessing(e) {
            this.isLoading = e
        },
        updateData(e) {
            const data = e
            // probably includes the results from other category
            if (data.includes_other_category) {
                this.includesOtherCategory = data.includes_other_category
            }
        },
    },
}
</script>

<style>
select {
    font-family: "FontAwesome", "sans-serif";
}
input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: #ea6a69;
    border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ea6a69;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ea6a69;
}
.range-wrap {
    /*width: 500px;*/
    position: relative;
}
.range-value {
    position: absolute;
    top: -50%;
}
.range-value span {
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #ea6a69;
    color: #fff;
    font-size: 12px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
}
.range-value span:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #ea6a69;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
}
[v-cloak] {
    display: none;
}
.pagination {
    /* display: inline-block; */
    margin: 30px 100px;
}
.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    /* margin: 0 4px; */
}
/* .pagination a.active {
  background-color: #8c0526;
  color: white;
  border: 1px solid #4caf50;
} */
.pagination a:hover:not(.active) {
    background-color: #8c0526;
}
.page-item.active a {
    color: white;
    background-color: #8c0526;
}

.product-box img {
    height: 240px;
}
</style>
