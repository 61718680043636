<template>
    <div class="col-sm-4 my-3 p-2" v-if="product">
        <div class="surplus-product-container d-flex flex-column justify-content-between">
            <div class="">
                <div>
                    <ImageItem
                        class="surplus-product-image img-fluid"
                        v-if="product.image"
                        :src="imgsrc + product.image"
                    />
                    <ImageItem
                        class="surplus-product-image"
                        v-else
                        :src="process && process.env.VUE_APP_FALLBACK_IMAGE_SRC"
                    />
                </div>
                <div class="p-2">
                    <div class="mb-1">
                        <span class="badge badge-danger">Sale</span>
                        <span class="mx-2 badge badge-success" v-if="product.verified"
                            >Verified</span
                        >
                    </div>
                    <div class="surplus-category-name" v-if="product.category.name.length < 20">
                        {{ product.category.name }}
                    </div>

                    <div class="surplus-category-name" v-if="product.category.name.length >= 20">
                        {{ product.category.name.substring(0, 20) + ".." }}
                    </div>

                    <div class="surplus-product-name" v-if="product.title.length < 18">
                        {{ product.title }}
                    </div>
                    <div class="surplus-product-name" v-if="product.title.length >= 18">
                        {{ product.title.substring(0, 18) + ".." }}
                    </div>
                </div>
            </div>
            <div>
                <div class="d-flex justify-content-between align-items-center px-2 border-top">
                    <div class="">
                        <div class="vacancies_box_rating py-2">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                        </div>
                    </div>
                    <div class="surplus-product-qty">
                        <span>Qnty: {{ product.quenty }}</span>
                    </div>
                </div>
                <div class="text-center">
                    <router-link
                        class="product-detail-link"
                        :to="{
                            name: 'single-product',
                            params: { id: product.id },
                        }"
                        >PRODUCT DETAILS</router-link
                    >
                </div>
                <div class="text-center" v-if="myProducts">
                    <!-- <a
                        v-if="!isAd"
                        class="product-detail-link ad-link"
                        href="javascript:void(0)"
                        @click="setSurplusAsAd()"
                        >{{ this.processingForm ? "Please Wait..." : "Set As Sponsored Ad" }}</a
                    >
                    <a
                        v-else
                        class="product-detail-link ad-link bg-secondary"
                        href="javascript:void(0)"
                        @click="unsetSurplusAsAd()"
                        ><i class="fa fa-times text-white"></i>
                        {{ this.processingForm ? "Please Wait..." : "Remove Sponsored Ad" }}</a
                    > -->
                </div>
                <div class="text-center" v-if="myProducts">
                    <a
                        class="product-detail-link bg-danger text-white"
                        href="javascript:void(0)"
                        @click="deleteSurplusItem()"
                    >
                        Delete Surplus Item
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import ImageItem from "./ImageItem.vue"
import request from "../../apis/request"

export default {
    emits: ["remove"],
    props: {
        product: {
            type: Object,
            default: () => null,
        },
        myProducts: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,
            isAd: false,
            processingForm: false,
        }
    },
    watch: {
        product(e) {
            console.log("PRODUCT UDPATE IN SURPLUS ITEM", e)
            this.isAd = !!e.ad
        },
    },
    mounted() {
        if (this.product) {
            this.isAd = !!this.product.ad
        }
    },
    components: { ImageItem },
    computed: {},
    methods: {
        async setSurplusAsAd() {
            if (this.processingForm) return
            this.processingForm = true
            try {
                await request.post(`/surplus/${this.product.id}/ad`)
                this.$toasted.success("Surplus posted as ad successfully.")
                this.isAd = true
            } catch (err) {
                const message = err.response && err.response.data && err.response.data.message
                this.$toasted.error(message || "Some Erorr Occured. Try again later!")
            }
            this.processingForm = false
        },
        async unsetSurplusAsAd() {
            if (this.processingForm) return
            this.processingForm = true
            try {
                await request.post(`/surplus/${this.product.id}/ad/delete`)
                this.$toasted.success("Surplus removed from ad.")
                this.isAd = false
            } catch (err) {
                const message = err.response && err.response.data && err.response.data.message
                this.$toasted.error(message || "Some Erorr Occured. Try again later!")
            }
            this.processingForm = false
        },
        async deleteSurplusItem() {
            console.log("dleete surplus item")
            const confirmed = confirm("Are you sure you want to delete this surplus?")
            if (!confirmed) return
            if (this.processingForm) return
            this.processingForm = true
            try {
                await request.post(`/surplus/${this.product.id}/delete`)
                this.$toasted.success("Surplus deleted.")
                this.$emit("remove", this.product.id)
            } catch (err) {
                const message = err.response && err.response.data && err.response.data.message
                if (message && err.response.data.operational) {
                    this.$toasted.error(message || "Some Erorr Occured. Try again later!")
                }
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
.surplus-product-container {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.6);
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;

    .surplus-category-name {
        font-size: 0.8em;
    }
    .surplus-product-name {
        font-size: 0.9em;
        font-weight: bold;
    }
    .surplus-product-image {
        width: 100%;
        height: auto;
    }
    .surplus-product-qty {
        font-size: 0.8em;
    }
    .product-detail-link {
        color: rgb(36, 36, 36);
        padding: 7px 10px;
        font-size: 0.9em;
        display: block;
        background: #facfcf;
        transition: 0.3s;

        &.ad-link {
            background: rgb(114, 180, 247);
            color: white;

            &:hover {
                background: rgb(148, 197, 247);
            }
        }

        &:hover {
            background: #aa3737;
            color: white !important;
        }
    }
}
</style>
